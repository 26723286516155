import { fromJS, Map } from "immutable";

import { ENVIRONMENT_ID_FIELD } from "Constants/constants";
import { loadUsersSuccess } from "Reducers/user";
import logger from "Libs/logger";

const LOAD_ACCESSES_START = "app/environmentAccesses/load_accesses_start";
const LOAD_ACCESSES_SUCCESS = "app/environmentAccesses/load_accesses_success";
const LOAD_ACCESSES_FAILURE = "app/environmentAccesses/load_accesses_failure";

const UPDATE_ACCESS_START = "app/environmentAccess/update_access_start";
const UPDATE_ACCESS_SUCCESS = "app/environmentAccess/update_access_success";
const UPDATE_ACCESS_FAILURE = "app/environmentAccess/update_access_failure";
const UPDATE_ACCESS_CANCEL = "app/environmentAccess/update_access_cancel";

const DELETE_ACCESS_START = "app/environmentAccess/delete_access_start";
const DELETE_ACCESS_SUCCESS = "app/environmentAccess/delete_access_success";
const DELETE_ACCESS_FAILURE = "app/environmentAccess/delete_access_failure";

const EDIT_LINE = "app/environmentAccess/edit_line";

export const editLine = (index, isNew) => ({
  type: EDIT_LINE,
  payload: index,
  meta: { isNew }
});

export const deleteAccess = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  access
) => {
  return async dispatch => {
    if (!access) {
      return false;
    }

    dispatch({ type: DELETE_ACCESS_START });

    try {
      await access.delete();
      dispatch({
        type: DELETE_ACCESS_SUCCESS,
        payload: access,
        meta: {
          environmentDescriptionId,
          projectDescriptionId,
          organizationDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "environment_delete_access",
        payload: access,
        meta: {
          environmentDescriptionId,
          projectDescriptionId,
          organizationDescriptionId
        }
      });
      dispatch({ type: DELETE_ACCESS_FAILURE, error: true, payload: err });
    }
  };
};

export const loadAccesses = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  environment
  // withUsers = false
) => {
  return async dispatch => {
    if (!environment) {
      return false;
    }

    dispatch({ type: LOAD_ACCESSES_START });

    try {
      const accesses = await environment.getUsers();

      dispatch({
        type: LOAD_ACCESSES_SUCCESS,
        payload: {
          accesses
        },
        meta: {
          environmentDescriptionId,
          projectDescriptionId,
          organizationDescriptionId
        }
      });

      const users = await Promise.all(
        accesses.map(async access => await access.getUser())
      );
      dispatch(loadUsersSuccess(users));
    } catch (err) {
      logger(err, {
        action: "environment_load_accesses",
        meta: {
          environmentDescriptionId,
          projectDescriptionId,
          organizationDescriptionId
        }
      });
      dispatch({ type: LOAD_ACCESSES_FAILURE, error: true, payload: err });
    }
  };
};

export const cancelUpdateAccess = () => ({ type: UPDATE_ACCESS_CANCEL });

export const updateAccess = (
  organizationDescriptionId,
  projectDescriptionId,
  environment,
  email,
  access = {},
  editedLine
) => {
  return async (dispatch, getState) => {
    if (!environment) {
      return false;
    }
    dispatch({ type: UPDATE_ACCESS_START });

    try {
      let newAccess;
      if (!access.id) {
        const result = await environment.addUser(email, access.role, false);

        newAccess = await result.getEntity();
      } else {
        const existingAccess = getState().environmentAccess.getIn([
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environment.id,
          access.id
        ]);
        const result = await existingAccess.update(access);

        newAccess = await result.getEntity();
      }

      dispatch({
        type: UPDATE_ACCESS_SUCCESS,
        payload: newAccess,
        meta: {
          environmentDescriptionId: environment[ENVIRONMENT_ID_FIELD],
          organizationDescriptionId,
          projectDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "environment_update_access",
        environment,
        email,
        access,
        editedLine,
        meta: {
          environmentDescriptionId: environment[ENVIRONMENT_ID_FIELD],
          organizationDescriptionId
        }
      });
      dispatch({
        type: UPDATE_ACCESS_FAILURE,
        payload: err.detail || err,
        meta: { editedLine }
      });
    }
  };
};

export default function environmentAccessReducer(state = new Map(), action) {
  switch (action.type) {
    case UPDATE_ACCESS_START:
      return state.set("updateLoading", true);
    case LOAD_ACCESSES_START:
      return state.set("loading", true);
    case UPDATE_ACCESS_SUCCESS:
      return state
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId,
            action.payload.id
          ],
          fromJS(action.payload)
        )
        .set("updateLoading", false)
        .set("editedLine", false)
        .set("isNew", false)
        .set("newAccess", action.payload);
    case DELETE_ACCESS_SUCCESS:
      return state
        .deleteIn([
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.payload.id
        ])
        .set("editedLine", false);
    case EDIT_LINE:
      return state
        .set("editedLine", action.payload)
        .set("isNew", action.meta.isNew);
    case UPDATE_ACCESS_FAILURE:
      return state
        .set("editedLine", action.meta.editedLine)
        .set("errors", action.payload)
        .set("updateLoading", false);
    case UPDATE_ACCESS_CANCEL:
      return state
        .set("editedLine", false)
        .set("errors", false)
        .set("isNew", false);
    case LOAD_ACCESSES_SUCCESS: {
      let accesses = fromJS(
        action.payload.accesses.reduce((orgProjEnvs, access) => {
          orgProjEnvs[access.id] = access;
          return orgProjEnvs;
        }, {})
      );

      return state
        .set("loading", false)
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId
          ],
          accesses
        )
        .set("errors", false);
    }
    case LOAD_ACCESSES_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    default:
      return state;
  }
}
